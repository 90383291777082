import axios from "axios";
import store from "../store/index";
import Swal from "sweetalert2";
import router from "@/router";

// export const baseUrl = "https://archives-api.tatweer-tech.host/";
// export const baseUrl = "http://localhost:5134/";
// export const baseUrl = "https://192.168.1.102:8082/";
 //export const baseUrl = "https://archive-api.smartway-demo.com/";
// export const baseUrl = "https://archivebooks-api.tatweer-tech.host/";
export const baseUrl = "https://archive-api.alufiq.com/";

axios.interceptors.request.use((config) => {
  config.baseURL = baseUrl;
  config.headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")) || false
      }`,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  if (config.method == "get") {
    store.state.getLoading = true;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    // if (response.config.method !== "get") {
    //   Swal({
    //     position: "top-end",
    //     icon: "success",
    //     title: "نجاح العملية",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // }

    if (response.config.method == "get") {
      store.state.getLoading = false;
    }

    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      router.push({ name: "login" });
    }
    error.config.method == "get" ? (store.state.getLoading = false) : "";
    var setError = [];
    for (var key in error.response.data.errors) {
      error.response.data.errors[key].forEach((element) => {
        setError.push(element);
      });
    }
    if (error.config.method !== "get") {
      // Swal({
      //   position: "top-end",
      //   icon: "error",
      //   title: setError[0],
      //   footer: "",
      //   showFooter: false,
      //   showConfirmButton: false,
      // });
    }
    if (error.config.method == "get") {
      store.state.getLoading = false;
    }
    // return Promise.reject(error);
  }
);

export default axios;
