import Vue from "vue";
import Vuex from "vuex";

import Auth from "./modules/auth";
import Slidbar from "./modules/sidebar";

// import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Slidbar
    // modules,
  },
});
