export default {
  state: {
    isSidebarShown: false,
  },
  getters: {
    isSidebarShown: (state) => state.isSidebarShown,
  },
  actions: {
    toggleSidebar({ commit }) {
      commit("SET_IS_SIDEBAR");
    },
  },
  mutations: {
    SET_IS_SIDEBAR(state) {
      state.isSidebarShown = !state.isSidebarShown;
    },
  },
};
