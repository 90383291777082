import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Auth/LoginPage.vue"),
  },
  {
    path: "/",
    name: "LayoutPage",
    component: () => import("../views/LayoutPage.vue"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: "/books",
        name: "books",
        component: () => import("@/views/books.vue"),
      },
      {
        path: "/lateArchive",
        name: "lateArchive",
        component: () => import("@/views/LateArchive.vue"),
      },
      {
        path: "/archive",
        name: "archive",
        component: () => import("@/views/archive.vue"),
      },
      {
        path: "/shareArchive",
        name: "archive",
        component: () => import("@/views/ShareArchive.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile.vue"),
      },
      // users
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/users.vue"),
      },
      //print
      {
        path: "/print",
        name: "print",
        component: () => import("../views/Print.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !localStorage.getItem("token")) {
    next({
      path: "login",
      replace: true,
    });
  } else {
    next();
  }
});

export default router;
