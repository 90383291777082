import $http from "@/plugins/axios.js";
import router from "@/router";

export default {
  state: {
    userInfo: {},
    me: {},
  },
  getters: {
    userInfo: (state) => state.userInfo,
    me: (state) => state.me,
  },
  actions: {
    async getMe({ commit }) {
      const request = await $http.get("/auth");
      commit("SET_ME", request.data);
      return request.data;
    },

    async login({ commit }, data) {
      try {
        const request = await $http.post("/auth/login", data);
        localStorage.setItem("token", JSON.stringify(request.data.data.token));
        localStorage.setItem("user_info", JSON.stringify(request.data.user));
        router.push("/home");
        commit("SET_USER", request.data);
      } catch (e) {
        return;
      }
    },

    logout({ commit }) {
      try {
        // await $http.post("/auth/user/logout");
        localStorage.removeItem("token");
        localStorage.removeItem("user_info");
        router.push({ name: "login" });
        return commit;
      } catch (e) {
        return;
      }
    },
  },
  mutations: {
    SET_USER(state, data) {
      state.userInfo = data.user;
    },
    SET_ME(state, data) {
      state.me = data;
    },
  },
};
