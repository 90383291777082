import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap";
import VueSweetalert2 from "vue-sweetalert2";
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
import VueCarousel from 'vue-carousel';
import Lingallery from 'lingallery';
import { BootstrapVueIcons } from 'bootstrap-vue'

Vue.component('lingallery', Lingallery);


Vue.use(VueCarousel);
Vue.use(VueSweetalert2);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-select/dist/vue-select.css";
import "@/assets/sass/main.scss";
Vue.use(VueAxios, axios);
import vSelect from "vue-select";
var PulseLoader = require("vue-spinner/src/PulseLoader.vue");

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)
Vue.component("v-select", vSelect);
Vue.use(LottieVuePlayer);
Vue.config.productionTip = false;
new Vue({
  components: {
    PulseLoader: PulseLoader,
  },
  router,
  axios,
  store,
  render: (h) => h(App),
}).$mount("#app");
